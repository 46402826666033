import { useAuthProvider } from "leeloo-react/component/hook/useAuthProvider";
import { useRouter } from "leeloo-react/component/hook/useRouter";
import { useEffect } from "react";
import { RouteEnum } from "../../../enum/RouteEnum";

export const QuickLog = () => {
    const authProvider = useAuthProvider();
    const { currentParamsRoute, goTo } = useRouter();

    useEffect(() => {
        if (authProvider != null) {
            const logRet = authProvider.login({ username: currentParamsRoute?.login, password: currentParamsRoute?.pin, identifier: currentParamsRoute?.siteId });
            logRet.then(() => {
                goTo(RouteEnum.SURVEY_EDIT.replace(":mode", "edit").replace(":id", currentParamsRoute?.formId));
            }, () => { });
        }
    }, [])

    return (<></>
    );
}