export enum EscarreLocalisationEnum {
    sacrum = 1,
    ischionDroite = 2,
    ischionGauche = 3,
    trochanterDroit = 4,
    trochanterGauche = 5,
    talonDroit = 6,
    talonGauche = 7,
    chevilleDroite = 8,
    chevilleGauche = 9,
    coudeDroit = 10,
    coudeGauche = 11,
    autre = 0
}

export enum EscarreOrigineEnum {
    origService = 0,
    origAutreService = 1,
    origAutreEtab = 2,
    origHospADomicile = 3,
    origDomicile = 4
}

export enum EscarreStadeEnum {
    escStade1 = 0,
    escStade2 = 1,
    escStade3 = 2,
    escStade4 = 3
}
export enum EscarreStadeShortEnum {
    "Stade 1" = 0,
    "Stade 2" = 1,
    "Stade 3" = 2,
    "Stade 4" = 3
}


