import React, { useContext, useEffect, useRef, useState } from "react";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { SelectField } from "leeloo-react/component/form/field/SelectField";
import { EquipementsItemDto, FormDto, EtudeInfoItemDto, PolesItemDto, ServiceItemDto, Step1 } from "../../../../model/api-dto";
import { useDataSource } from "leeloo-react/component/hook/useDataSource";
import { useDataProvider } from "leeloo-react/component/hook/useDataProvider";
import { SelectOptionType } from "leeloo-react/model/field/SelectOptionType";
import { EtudeInfoType } from "../../../../model/Survey/EtudeInfoType";
import { ContextForm } from "leeloo-react/component/form/Form";
import { SelectCheckRadioField } from "leeloo-react/component/form/field/SelectCheckRadioField";

type Props = {
    etudeInfoState: EtudeInfoType
}

export const SelectPoleService = (props: Props) => {
    const dsPole = useDataSource<PolesItemDto>({ resource: ResourceDataProviderEnum.POLES });
    const dsService = useDataSource<{ value: string, label: string, dataComplement: number }>({ resource: ResourceDataProviderEnum.SERVICE, noLoadAfterInit: true });
    const { form } = useContext(ContextForm);

    const dataListService = useRef([]);

    useEffect(() => {
        dataListService.current = dsService.dataList as any
    }, [dsService.dataList])

    useEffect(() => {
        if (form.getFieldProps("poleId").value) {
            dsService.updateFilter({ id: form.getFieldProps("poleId").value })
        } else {
            dsService.updateFilter({})
        }
    }, [form.getFieldProps("poleId").value])

    return (
        <>
            {(props.etudeInfoState.poleManagement == true && props.etudeInfoState.servManagement == true) &&
                <>
                    <SelectField className="mb-3"
                        name="poleId"
                        label="Sélectionnez votre pôle"
                        // clé de la valeur correspondant au DTO inchangé
                        isSearchable={false}
                        // la resource externe sous forme de dataSource pour charger les données
                        externalResource={{ dataSource: dsPole, labelKey: "label", valueKey: "id" }}
                    />
                    <SelectField className="mb-3"
                        label="Sélectionnez votre service"
                        name="serviceId"
                        // clé de la valeur correspondant au DTO inchangé
                        isSearchable={false}
                        externalResource={{ dataSource: dsService, labelKey: "label", valueKey: "value" }}
                        //onChangeValue={onChangeService}
                        onFormValueChange={(cv, newData, nameChange) => {
                            if (nameChange == "poleId") {
                                return null;
                            }
                            return cv;
                        }}
                    />
                </>
            }

            {(props.etudeInfoState.poleManagement == true && props.etudeInfoState.servManagement == false) &&
                <SelectField className="mb-3"
                    name="poleId"
                    label="Sélectionnez votre pôle"
                    // clé de la valeur correspondant au DTO inchangé
                    isSearchable={false}
                    isClearable={false}
                    // la resource externe sous forme de dataSource pour charger les données
                    externalResource={{ dataSource: dsPole, labelKey: "label", valueKey: "id" }} />
            }

            {(props.etudeInfoState.poleManagement == false && props.etudeInfoState.servManagement == true) &&
                <SelectField className="mb-3"
                    label="Sélectionnez votre service"
                    name="serviceId"
                    // clé de la valeur correspondant au DTO inchangé
                    isSearchable={false}
                    externalResource={{ dataSource: dsService, labelKey: "label", valueKey: "value" }}
                //onChangeValue={onChangeService}
                />
            }

            <SelectCheckRadioField className="mb-3" name="segmentation" label="segmentation" isMulti={false}
                optionsList={[{ label: "mCO", value: 0 }, { label: "sSR", value: 1 }, { label: "eHPADUSLD", value: 2 }]}
                onFormValueChange={(cv, newData: any, nameChange) => {
                    if (nameChange == "serviceId") {
                        let selectedService = dataListService.current.find((f: any) => f.value == newData.serviceId) as any;

                        if (selectedService?.dataComplement !== undefined && selectedService?.dataComplement !== null) {
                            console.log("datacomplement", selectedService.dataComplement);
                            return selectedService.dataComplement;
                        }
                    }
                    return cv;
                }}
            />
        </>
    )
}