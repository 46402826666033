import React, { useEffect } from "react";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../enum/RouteEnum";
import { BaseContainer } from "../../layout/BaseContainer";
import { Button } from "react-bootstrap";
import { useAuthProvider } from "leeloo-react/component/hook/useAuthProvider";
import { useSelector } from "react-redux";
import { StateLeelooType } from "leeloo-react/model/store/StateLeelooType";
import { useDataSource } from "leeloo-react/component/hook/useDataSource";
import { SelectField } from "leeloo-react/component/form/field/SelectField";
import logo from "../../../asset/img/logo.png";
import { Form } from "leeloo-react/component/form/Form";
import { LogoutLink } from "leeloo-react/component/auth/logout/LogoutLink";
import { Icon } from "leeloo-react/component/display/Icon";
import { useRouter } from "leeloo-react/component/hook/useRouter";
import { SiteItemDto } from "../../../model/api-dto";

export const SiteSelectionForm = () => {

    const dsSite = useDataSource<SiteItemDto>({ resource: ResourceDataProviderEnum.SITE });
    const authProvider = useAuthProvider();
    const router = useRouter();

    const siteSelection = (v: any) => {
        if (authProvider != null) {
            const logRet = authProvider.login({ username: "", password: "", identifier: v });
            logRet.then(() => {
                router.goTo(RouteEnum.HOME);
            }, () => { });
        }
    }

    useEffect(() => {
        console.log(dsSite.dataList);

        if (dsSite.dataList.length == 1) {
            siteSelection(dsSite.dataList[0].id)
        }

    }, [dsSite.isReady])

    const messageLogout = dsSite.dataList.length <= 0 ? "Aucun site pour cette étude" : "Ce n'est pas la bonne étude ?";

    return (
        <section className="vh-100" style={{ backgroundColor: "#ddd" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong">
                            <div className="card-body p-5 text-center">
                                <img className="mb-2" src={logo} width={400} style={{ opacity: 1 }} />

                                <h3>Connexion</h3>
                                {authProvider &&
                                    <div className="mb-4">Etude: {authProvider.authData.username}</div>
                                }

                                {dsSite.dataList.length > 0 &&
                                    <Form initialValue={{}} >
                                        <div className="col-md-12">
                                            <SelectField
                                                name="refSite"
                                                label="Sélectionnez votre site"
                                                // clé de la valeur correspondant au DTO inchangé
                                                isSearchable={false}
                                                // la resource externe sous forme de dataSource pour charger les données
                                                externalResource={{ dataSource: dsSite, labelKey: "label", valueKey: "id" }}
                                                onChangeValue={siteSelection}
                                            />
                                        </div>
                                    </Form>
                                }
                                <div className="mt-5">
                                    {messageLogout}
                                    <LogoutLink className="nav-link"><div style={{ cursor: "pointer" }}><Icon name="uil uil-signout" /> Se déconnecter</div></LogoutLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
