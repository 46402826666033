import { RouteType } from "leeloo-react/model/RouteType";
import { SurveyEditAddPage } from "../components/page/survey/SurveyEditAddPage";
import { SurveyList } from "../components/page/survey/SurveyList";
import { RouteEnum } from "../enum/RouteEnum";
import { SiteSelectionForm } from "../components/page/site/SiteSelectionForm";
import { RoleEnum } from "../enum/RoleEnum.";
import { QuickLog } from "../components/page/auth/QuickLog";

/**
 * Configuration des routes exprimé ici
 */
export const RouteConfig: RouteType[] = [
    {
        label: "QuickLog",
        routeUrl: RouteEnum.QUICK_LOG,
        pageComponent: QuickLog
    },
    {
        label: "Questionnaires",
        routeUrl: RouteEnum.HOME,
        pageComponent: SurveyList,
        rolesAccess: [RoleEnum.HOME, RoleEnum.USER]
    },
    {
        label: "Linet - Site",
        routeUrl: RouteEnum.SITE,
        pageComponent: SiteSelectionForm,
        rolesAccess: [RoleEnum.HOME, RoleEnum.USER]
    },
    {
        label: "Questionnaire",
        routeUrl: RouteEnum.SURVEY_ADD,
        pageComponent: SurveyEditAddPage,
        rolesAccess: [RoleEnum.HOME, RoleEnum.USER]
    },
    {
        label: "Questionnaire",
        routeUrl: RouteEnum.SURVEY_EDIT,
        pageComponent: SurveyEditAddPage,
        rolesAccess: [RoleEnum.HOME, RoleEnum.USER]
    }
]
