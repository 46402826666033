import { useRouter } from "leeloo-react/component/hook/useRouter";
import { useToast } from "leeloo-react/component/hook/useToast";
import React, { useEffect, useRef, useState } from "react";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { BaseContainer } from "../../layout/BaseContainer";

import { SubmitBtn } from "leeloo-react/component/form/action/SubmitBtn"
import { InputField } from "leeloo-react/component/form/field/InputField"
import { Form } from "leeloo-react/component/form/Form"
import { SubForm } from "leeloo-react/component/form/subForm/SubForm"
import { SubmitSubFormLink } from "leeloo-react/component/form/subForm/SubmitSubFormLink"
import { RouteEnum } from "../../../enum/RouteEnum";
import { SelectCheckRadioField } from "leeloo-react/component/form/field/SelectCheckRadioField";
import { SelectField } from "leeloo-react/component/form/field/SelectField";
import { EquipementsItemDto, FormDto, EtudeInfoItemDto, PolesItemDto, ServiceItemDto, Step1 } from "../../../model/api-dto";
import { useDataSource } from "leeloo-react/component/hook/useDataSource";
import { useModal } from "leeloo-react/component/hook/useModal";
import * as Yup from 'yup';
import { DataTable } from "leeloo-react/component/dataTable/DataTable";
import { SimpleColumn } from "leeloo-react/component/dataTable/column/SimpleColumn";
import { ModalForm } from "leeloo-react/component/form/ModalForm";
import { SelectListField } from "leeloo-react/component/form/field/SelectListField";
import { EnumColumn } from "leeloo-react/component/dataTable/column/EnumColumn";
import { EscarreLocalisationEnum, EscarreOrigineEnum, EscarreStadeEnum, EscarreStadeShortEnum } from "../../../enum/form/EscarreEnum";
import { DatePickerField } from "leeloo-react/component/form/field/DatePickerField";
import { useDataProvider } from "leeloo-react/component/hook/useDataProvider";
import { CheckField } from "leeloo-react/component/form/field/CheckField";
import { RiskScaleEnum } from "../../../enum/form/RiskEnum";
import { useTrans } from "leeloo-react/component/hook/useTrans";
import { SelectOptionType } from "leeloo-react/model/field/SelectOptionType";
import { SelectPoleService } from "./SelectField/SelectPoleService";
import { EtudeInfoType } from "../../../model/Survey/EtudeInfoType";
import { NetProvider } from "../../../dataProvider/NetProvider";

export const SurveyEditAddPage = () => {
    const { currentParamsRoute, goTo } = useRouter();
    const toast = useToast();
    const { confirm } = useModal();
    const dsEquipement = useDataSource<EquipementsItemDto>({ resource: ResourceDataProviderEnum.EQUIPEMENTS });

    const dataProvider = useDataProvider();
    const dataProviderNet = useDataProvider({ customDp: NetProvider }) as ReturnType<typeof NetProvider>;

    // State pour le bouton done (a remonter en mode edit)
    const [doneState, setDoneState] = useState(false);

    // State pour l'affichage conditionnel de champ sur des etape differentes du questionnaire (a remonter + maj en mode edit)
    const [selfMovingState, setSelfMovingState] = useState(true);
    const [segmentationState, setSegmentationState] = useState<number | undefined>(undefined);

    // State set au "allowDisplay" pour afficher ou nom des elements d'une meme etape du formulaire qui ne sont pas des champs
    const [haveEscarreState, setHaveEscarreState] = useState(false);
    const [haveDynamicSupportState, setDynamicSupportState] = useState(false);
    const [haveBasicHotelSupportState, setBasicHotelSupportState] = useState(false);
    const [havePrevStaticSupportState, setPrevStaticSupportState] = useState(false);
    const [etudeInfoState, setEtudeInfoState] = useState<EtudeInfoType>({ poleManagement: false, servManagement: false, riskScale: 0 });
    const { t } = useTrans();

    useEffect(() => {
        if (dataProvider.getOne) {
            // on me l'id "rien" car il faut envoyer un parametre pour respecter le framework
            dataProvider.getOne(ResourceDataProviderEnum.ETUDEINFO, { id: "rien" }).then((d) => {
                setEtudeInfoState({ poleManagement: d.data.poleManagement, servManagement: d.data.serviceManagement, riskScale: d.data.riskScale });
            })
        }
    }, [])

    // Retourne le block avec les etapes du formulaire et le bouton retour
    const stepCounterBlockContent = (current: number) => {
        const steps = [{ number: 1, target: "step1", label: "Etape 1" }, { number: 2, target: "step2", label: "Etape 2" }, { number: 3, target: "step3", label: "Etape 3" }, { number: 4, target: "step4", label: "Etape 4" }]
        return (
            <div className="col-md-12 mb-5">
                {steps.map((s, idx) => {
                    if (current == s.number) {
                        return <button className="btn btn-dark me-3" style={{ cursor: "default" }} key={"button-" + idx}>{s.label}</button>
                    }
                    else if (current > s.number) {
                        // Non cliquable si le form est en mode creation (etape 1)
                        if (!currentParamsRoute?.id)
                            return <button className="btn btn-primary me-3" disabled style={{ cursor: "default" }} key={"button-" + idx}>{s.label}</button>
                        return <SubmitSubFormLink className="btn btn-primary me-3" targetFormName={s.target} label={s.label} key={"button-" + idx} />
                    }
                    else {
                        // Non cliquable si le form est en mode creation (etape 1)
                        if (!currentParamsRoute?.id)
                            return <button className="btn btn-light me-3" disabled style={{ cursor: "default" }} key={"button-" + idx}>{s.label}</button>
                        return <SubmitSubFormLink className="btn btn-light me-3" targetFormName={s.target} label={s.label} key={"button-" + idx} />
                    }
                })}
                <div style={{ float: "right" }} ><a href="#" onClick={() => { confirm("Les données non sauvegardées seront perdues !", "Revenir à la liste ?").then(async (e) => { if (e) { goTo(RouteEnum.HOME); } }) }}> Retour à la liste </a></div>
            </div>
        );
    }

    // Retourne le block avec les boutons en pied de page
    const submitBlockContent = (nextStep: string | undefined = undefined, prevStep: string | undefined = undefined) => {
        let prevNextBlock = (<></>);

        if (prevStep && nextStep) {
            prevNextBlock = (
                <div className="mt-3 col-md-12">
                    <div className="col-md-6" style={{ display: "inline-block", paddingRight: "10px" }}>
                        <SubmitSubFormLink className="btn btn-light col-md-12" targetFormName={prevStep} label="Précédent" />
                    </div>
                    <div className="col-md-6" style={{ float: "right", display: "inline-block", paddingLeft: "10px" }}>
                        <SubmitSubFormLink className="btn btn-primary col-md-12" targetFormName={nextStep} label="Suivant" />
                    </div>
                </div>
            );
        }
        else if (nextStep) {
            prevNextBlock = (
                <div className="mt-3 col-md-12">
                    {currentParamsRoute?.id &&
                        <SubmitSubFormLink className="btn btn-primary col-md-12" targetFormName={nextStep} label="Suivant" />
                    }
                    {/* Si on est en mode creation le bouton suivant submit le formulaire */}
                    {!currentParamsRoute?.id &&
                        <SubmitBtn className="btn btn-primary col-md-12" label="Suivant" />
                    }
                </div>
            );
        }
        else if (prevStep) {
            prevNextBlock = (
                <div className="mt-3 col-md-12">
                    <SubmitSubFormLink className="btn btn-light col-md-12" targetFormName={prevStep} label="Précédent" />
                </div>
            );
        }

        return (
            <div className="mb-5 col-md-12">
                {prevNextBlock}
                {currentParamsRoute?.id && !doneState &&
                    <div className="mt-3 col-md-12">
                        <SubmitBtn className="btn btn-dark col-md-12" label="Enregistrer et quitter" />
                    </div>
                }
                {currentParamsRoute?.id && doneState &&
                    <div className="mt-3 col-md-12">
                        <SubmitBtn className="btn btn-success col-md-12" label="Valider et quitter" />
                    </div>
                }
            </div>
        );
    }

    return (
        <BaseContainer>

            <Form
                initialValue={{}}
                // si on a un id et un mode creation, on vient de passer l'etape 1 alors on commence a l'etape 2
                initialSubFormName={(currentParamsRoute?.id && currentParamsRoute?.mode == "new") ? "step2" : "step1"}
                id={currentParamsRoute?.id}
                resource={ResourceDataProviderEnum.FORM}
                onSubmitEndUpdate={(e, ret) => {
                    // Si le formulaire n'a pas d'id on le sauvegarde et on redirige vers le form avec id et un mode creation pour initialiser la step de depart
                    if ((currentParamsRoute?.mode == "new") && !(currentParamsRoute?.id) && (ret?.newId)) {
                        toast("Fomulaire initialisé pour lit " + e.step1?.bedNb, "success");
                        goTo(RouteEnum.SURVEY_EDIT.replace(":mode", "new").replace(":id", ret.newId.toString()));
                    }
                    else {
                        if (e.step4?.done && dataProvider.getOne) {
                            dataProvider.getOne(ResourceDataProviderEnum.FORM_STATUS, { id: currentParamsRoute?.id }).then(
                                (d) => {
                                    if (e.step4?.done != d.data.done) {
                                        toast("message.updateSuccess", "success");
                                        toast(d.data.report, "error");
                                    }
                                    else {
                                        toast("message.updateSuccess", "success");
                                        toast("Formulaire validé", "success");
                                        goTo(RouteEnum.HOME);
                                    }
                                }
                            )
                        }
                        else {
                            toast("message.updateSuccess", "success");
                            goTo(RouteEnum.HOME);
                        }
                    }
                }}
                onDataApiLoaded={async (d: FormDto) => { setDoneState(d?.step4?.done == true); setSelfMovingState(d?.step2?.selfMovingPatient == 1); setSegmentationState(d?.step1?.segmentation); }}
                onChangeData={(v) => {
                    if (currentParamsRoute?.id) {
                        setSelfMovingState(v.step2?.selfMovingPatient == 1);
                        setSegmentationState(v.step1?.segmentation);
                    }
                    if (v.step1?.bedNb && dataProvider.getOne && !currentParamsRoute?.id) {
                        dataProviderNet.checkBedUnicity(ResourceDataProviderEnum.FORM_EXIST, v.step1?.bedNb, v.step1?.serviceId).then(
                            (d) => {
                                if (d.data == 1) {
                                    toast("Ce numéro de lit existe déjà", "error");
                                }
                            }
                        )
                    }
                }}
            >
                {/* etape 1  */}
                <SubForm name="step1"
                    validationSchema={{
                        bedNb: Yup.string().nullable().required("Numéro de lit obligatoire")
                    }}
                >
                    {stepCounterBlockContent(1)}

                    <h4 className="mb-4">1 - LIEU D'HOSPITALISATION</h4>


                    <SelectPoleService etudeInfoState={etudeInfoState} />



                    <InputField className="mb-3" name="bedNb" label="bedNb" />
                    <DatePickerField className="mb-3" name="dateArrival" label="dateArrival" />

                    <hr className="mt-4 mb-4"></hr>
                    <h4 className="mb-4">2 - CARACTERISTIQUES GENERALES</h4>


                    <SelectCheckRadioField className="mb-3" name="sexe" label="sexe" isMulti={false}
                        optionsList={[{ label: "homme", value: 0 }, { label: "femme", value: 1 }]}
                    />

                    <InputField className="mb-3" name="age" type="number" label="age" min={0} max={120}/>
                    <SelectCheckRadioField className="mb-3" name="knownIMC" label="knownIMC" isMulti={false}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />

                    <InputField className="mb-3" name="imc" label="iMC" type="number" min={0} max={60} allowDisplay={(d: any) => { return d?.knownIMC == 1; }} />

                    <hr className="mt-4 mb-4"></hr>
                    <h4 className="mb-4">3 - EVALUATION DU NIVEAU DE RISQUE D'ESCARRE</h4>

                    <label className="form-label">Echelle de risque utilisée</label>
                    <span className="mb-3" >{t(RiskScaleEnum[etudeInfoState.riskScale].toString())}</span>

                    <InputField className="mb-3" name="dayScore" type="number" label="dayScore" min={0} max={23}/>

                    <SelectCheckRadioField className="mb-3" name="riskEval" label="riskEval" isMulti={false}
                        optionsList={[
                            { label: RiskScaleEnum[etudeInfoState.riskScale].toString() + "RisqueFaible", value: 0 },
                            { label: RiskScaleEnum[etudeInfoState.riskScale].toString() + "RisqueModere", value: 1 },
                            { label: RiskScaleEnum[etudeInfoState.riskScale].toString() + "RisqueEleve", value: 2 },
                            { label: RiskScaleEnum[etudeInfoState.riskScale].toString() + "RisqueTresEleve", value: 3 }]}
                    />

                    {submitBlockContent("step2")}
                </SubForm>

                {/* etape 2  */}
                <SubForm name="step2">
                    {stepCounterBlockContent(2)}

                    <h4 className="mb-4">4 - MOBILITE ET CAPACITE DE REPOSITIONNEMENT</h4>

                    <SelectCheckRadioField className="mb-3" name="patientMobilityEval" label="patientMobilityEval" isMulti={false}
                        optionsList={[{ label: "autonomieTotale", value: 0 }, { label: "necessiteAide", value: 1 }, { label: "confineLitOuFauteuil", value: 2 }]}
                    />
                    <SelectCheckRadioField className="mb-3" name="selfMovingPatient" label="selfMovingPatient" isMulti={false}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />
                    <SelectCheckRadioField className="mb-3" name="patientTimeWithoutMoving" label="patientTimeWithoutMoving" isMulti={false}
                        optionsList={[{ label: "alite2424", value: 0 }, { label: "moinsDe2h", value: 1 }, { label: "de2a4h", value: 2 }, { label: "de4a6h", value: 3 }, { label: "plusDe6h", value: 4 }, { label: "autonome", value: 5 }]}
                    />
                    <SelectCheckRadioField className="mb-3" name="patientDailyTimeInBed" label="patientDailyTimeInBed" isMulti={false}
                        optionsList={[{ label: "moinsDe12h", value: 0 }, { label: "de12a15h", value: 1 }, { label: "de15a20h", value: 2 }, { label: "plusDe20h", value: 3 }]}
                    />

                    <hr className="mt-4 mb-4"></hr>
                    <h4 className="mb-4">5 - EVALUATION DE LA CONTINENCE</h4>

                    <SelectCheckRadioField className="mb-3" name="patientContinenceEval" label="patientContinenceEval" isMulti={false}
                        optionsList={[{ label: "totalementContinent", value: 0 }, { label: "incontinenceUrinaireSeule", value: 1 }, { label: "incontinenceFecaleSeule", value: 2 }, { label: "incontinenceUrinaireEtFecale", value: 3 }, { label: "sondeUrinaireSeule", value: 4 }, { label: "sondeUrinaireEtIncontinenceFecale", value: 5 }]}
                    />

                    {submitBlockContent("step3", "step1")}
                </SubForm>

                {/* etape 3  */}
                <SubForm name="step3">
                    {stepCounterBlockContent(3)}

                    <h4 className="mb-4">6 - DETAIL DES ESCARRES</h4>

                    <SelectCheckRadioField className="mb-3" name="haveEscarre" label="haveEscarre" isMulti={false} allowDisplay={(d) => { setHaveEscarreState(d.haveEscarre); return true; }}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />
                    <>
                        {(haveEscarreState == true) &&
                            <>
                                <label className="mt-2 form-label" style={{ marginBottom: -30 }}>Liste des escarres</label>
                                <div className="mb-5 subform-list">
                                    <DataTable
                                        allowDelete={() => true}
                                        resource={ResourceDataProviderEnum.ESCARRE}
                                        disabledPagination={true}
                                        disabledTotalFound={true}
                                        initialParams={{
                                            filter: {
                                                formId: currentParamsRoute?.id
                                            }
                                        }}
                                        modalForm={
                                            <ModalForm
                                                initialValue={{
                                                    formId: currentParamsRoute?.id, localisation: undefined, stade: undefined, origine: undefined, localisationLabel: undefined
                                                }}
                                                validationSchema={{
                                                    localisation: Yup.number().nullable().required("Localisation de l'escarre requise"),
                                                    stade: Yup.number().nullable().required("Stade requis"),
                                                    origine: Yup.number().nullable().required("Origine requise"),
                                                    formId: Yup.string().required(),
                                                    localisationLabel: Yup.string().nullable()
                                                }}
                                            >
                                                <SelectField className="mb-3" name="localisation" label="localisation" optionsEnum={EscarreLocalisationEnum} />
                                                <InputField className="mb-3" name="localisationLabel" label="Précisez la localisation" allowDisplay={(d: any) => { return d?.localisation == 0; }} />
                                                <SelectField className="mb-3" name="stade" label="stade" optionsEnum={EscarreStadeEnum} />
                                                <SelectField className="mb-3" name="origine" label="origine" optionsEnum={EscarreOrigineEnum} />

                                            </ModalForm>
                                        }
                                    >
                                        <EnumColumn title="localisation" field="localisation" enum={EscarreLocalisationEnum} />
                                        <SimpleColumn title="localisationLabel" field="localisationLabel" />
                                        <EnumColumn title="stade" field="stade" enum={EscarreStadeShortEnum} />
                                        <EnumColumn title="origine" field="origine" enum={EscarreOrigineEnum} />
                                    </DataTable>
                                </div>
                            </>
                        }
                    </>
                    <>
                        {(haveEscarreState == true) &&
                            <span style={{ fontStyle: "italic", fontWeight: 400 }}>Si présence d'une escarre Stade 1, 2, 3 ou 4 :</span>
                        }
                        <SelectCheckRadioField className="mb-3" name="tracedCareHMFile" label="tracedCareHMFile" isMulti={false} allowDisplay={(d: any) => { return d?.haveEscarre == 1; }}
                            optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                        />
                    </>


                    {submitBlockContent("step4", "step2")}
                </SubForm>

                {/* etape 4  */}
                <SubForm name="step4"
                    validationSchema={{
                        eqDynamicSupportPrecision: Yup.string().nullable().max(150, "Le nombre de caractère maximum autorisé est de 150"),
                        eqPrevStaticSupportPrecision: Yup.string().nullable().max(150, "Le nombre de caractère maximum autorisé est de 150"),
                        eqBasicHotelSupportPrecision: Yup.string().nullable().max(150, "Le nombre de caractère maximum autorisé est de 150"),
                        eqSitPillowPrecision: Yup.string().nullable().max(150, "Le nombre de caractère maximum autorisé est de 150"),
                        eqDischargeBootPrecision: Yup.string().nullable().max(150, "Le nombre de caractère maximum autorisé est de 150"),
                        eqDischargePillowPrecision: Yup.string().nullable().max(150, "Le nombre de caractère maximum autorisé est de 150"),
                        eqDynamicSupportName: Yup.string().nullable().max(50, "Le nombre de caractère maximum autorisé est de 50"),
                        eqPrevStaticSupportName: Yup.string().nullable().max(50, "Le nombre de caractère maximum autorisé est de 50"),
                    }}
                >
                    {stepCounterBlockContent(4)}

                    <h4 className="mb-4">7 - ADEQUATION DE L'EQUIPEMENT AU LIT ET AU FAUTEUIL</h4>

                    <div style={{ display: (!haveDynamicSupportState && !havePrevStaticSupportState) || haveBasicHotelSupportState ? "block" : "none" }}>
                        <h5>Support Hôtelier simple</ h5>
                        <SelectCheckRadioField className="mb-3" name="eqBasicHotelSupport" label="eqPresent" isMulti={false}
                            optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}

                        />
                        <SelectCheckRadioField className="mb-3" name="eqBasicHotelSupportOk" label="eqQuestion" isMulti={false} allowDisplay={(d: any) => { return d?.eqBasicHotelSupport == 1; }}
                            optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                        />

                        <SelectCheckRadioField className="mb-5" name="eqBasicHotelSupportAction" label="eqChangeNeeded" isMulti={false} allowDisplay={(d: any) => { return d?.eqBasicHotelSupport == 1 && d?.eqBasicHotelSupportOk == 0; }}
                            optionsList={[{ label: "Gamme  inférieure", value: 1 }, { label: "Gamme supérieure", value: 2 }]}
                        />
                    </div>

                    <div style={{ display: (!haveDynamicSupportState && !haveBasicHotelSupportState) || havePrevStaticSupportState ? "block" : "none" }}>
                        <h5>Support Statique de prévention</ h5>
                        <SelectCheckRadioField className="mb-3" name="eqPrevStaticSupport" label="eqPresentMousse" isMulti={false}
                            optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                        />
                        <SelectCheckRadioField className="mb-3" name="eqPrevStaticSupportOk" label="eqQuestion" isMulti={false} allowDisplay={(d: any) => { return d?.eqPrevStaticSupport == 1; }}
                            optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                        />

                        <SelectCheckRadioField className="mb-5" name="eqPrevStaticSupportAction" label="eqChangeNeeded" isMulti={false} allowDisplay={(d: any) => { return d?.eqPrevStaticSupport == 1 && d?.eqPrevStaticSupportOk == 0; }}
                            optionsList={[{ label: "Gamme  inférieure", value: 1 }, { label: "Gamme supérieure", value: 2 }]}
                        />
                    </div>

                    <div style={{ display: (!haveBasicHotelSupportState && !havePrevStaticSupportState) || haveDynamicSupportState ? "block" : "none" }}>
                        <h5>Support Dynamique/motorisé</ h5>
                        <SelectField className="mb-3"
                            name="eqDynamicSupportEquipementId"
                            label="Sélectionnez votre équipement"
                            // clé de la valeur correspondant au DTO inchangé
                            isSearchable={false}
                            // la resource externe sous forme de dataSource pour charger les données
                            externalResource={{ dataSource: dsEquipement, labelKey: "label", valueKey: "id" }}
                            allowDisplay={(d: any) => {
                                setDynamicSupportState(d?.eqDynamicSupportEquipementId);
                                setBasicHotelSupportState(d?.eqBasicHotelSupport == 1);
                                setPrevStaticSupportState(d?.eqPrevStaticSupport == 1);
                                return true;
                            }}
                        />
                        <SelectCheckRadioField className="mb-3" name="eqDynamicSupportOk" label="eqQuestion" isMulti={false} allowDisplay={(d: any) => { return d?.eqDynamicSupportEquipementId != undefined; }}
                            optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                        />

                        <SelectCheckRadioField className="mb-5" name="eqDynamicSupportAction" label="eqChangeNeeded" isMulti={false} allowDisplay={(d: any) => { return d?.eqDynamicSupportEquipementId != undefined && d?.eqDynamicSupportOk == 0; }}
                            optionsList={[{ label: "Gamme  inférieure", value: 1 }, { label: "Gamme supérieure", value: 2 }]}
                        />
                    </div>


                    < h5>Coussin de siège</ h5>
                    <SelectField className="mb-3"
                        name="eqSitPillowType"
                        label="Sélectionnez votre équipement"
                        // clé de la valeur correspondant au DTO inchangé
                        isSearchable={false}
                        optionsList={[{ label: "aucun", value: null }, { label: "mousse", value: 0 }, { label: "gel", value: 1 }, { label: "Air statique", value: 2 }, { label: "Air dynamique", value: 3 }]}
                    />
                    <SelectCheckRadioField className="mb-3" name="eqSitPillowOk" label="eqQuestion" isMulti={false} allowDisplay={(d: any) => { return d?.eqSitPillowType != undefined; }}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />

                    <SelectCheckRadioField className="mb-5" name="eqSitPillowAction" label="eqChangeNeeded" isMulti={false} allowDisplay={(d: any) => { return d?.eqSitPillowType != undefined && d?.eqSitPillowOk == 0; }}
                        optionsList={[{ label: "Gamme  inférieure", value: 1 }, { label: "Gamme supérieure", value: 2 }]}
                    />


                    < h5>Présence de matériel de décharge talonnière</ h5>
                    <SelectCheckRadioField className="mb-3" name="eqDischargeBoot" label="eqPresent" isMulti={false}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />
                    <SelectCheckRadioField className="mb-3" name="eqDischargeBootOk" label="eqQuestion" isMulti={false} allowDisplay={(d: any) => { return d?.eqDischargeBoot == 1; }}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />
                    <SelectCheckRadioField className="mb-5" name="eqDischargeBootAction" label="eqChangeNeeded" isMulti={false} allowDisplay={(d: any) => { return d?.eqDischargeBoot == 1 && d?.eqDischargeBootOk == 0; }}
                        optionsList={[{ label: "Gamme  inférieure", value: 1 }, { label: "Gamme supérieure", value: 2 }]}
                    />


                    < h5 >Présence de matériel de positionnement</ h5>
                    <SelectCheckRadioField className="mb-3" name="eqDischargePillowBoot" label="eqPresent" isMulti={false}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />
                    <SelectCheckRadioField className="mb-3" name="eqDischargePillowOk" label="eqQuestion" isMulti={false} allowDisplay={(d: any) => { return d?.eqDischargePillowBoot == 1; }}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />
                    <SelectCheckRadioField className="mb-5" name="eqDischargePillowAction" label="eqChangeNeeded" isMulti={false} allowDisplay={(d: any) => { return d?.eqDischargePillowBoot == 1 && d?.eqDischargePillowOk == 0; }}
                        optionsList={[{ label: "Gamme  inférieure", value: 1 }, { label: "Gamme supérieure", value: 2 }]}
                    />

                    <hr className="mt-2 mb-4"></hr>
                    <h4 className="mb-4">8 - PRESENCE D'UNE DOCUMENTATION PATIENT</h4>

                    <SelectCheckRadioField className="mb-3" name="prevRiskEvalTracability" label={segmentationState == 2 ? "prevRiskEvalTracability6Month" : "prevRiskEvalTracability48H"} isMulti={false}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />

                    <SelectCheckRadioField className="mb-3" name="escarreAnticipationAction" label="escarreAnticipationAction" isMulti={false} allowDisplay={(d: any) => { return selfMovingState == false; }}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />

                    <SelectCheckRadioField className="mb-3" name="patientMovementAction" label="patientMovementAction" isMulti={false} allowDisplay={(d: any) => { return selfMovingState == false; }}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />

                    <SelectCheckRadioField className="mb-3" name="prescribedComplement" label="prescribedComplement" isMulti={false}
                        optionsList={[{ label: "oui", value: 1 }, { label: "non", value: 0 }]}
                    />

                    <SelectCheckRadioField className="mb-3" name="enrichment" label="enrichment" isMulti={false} allowDisplay={(d: any) => { return d?.prescribedComplement == 1; }}
                        optionsList={[{ label: "Plateau", value: 0 }, { label: "Complément Hyperprotéiné", value: 1 }, { label: "Les deux", value: 2 }]}
                    />

                    <hr></hr>

                    <CheckField className="mb-3 mt-3" name="done" label="Saisie terminée. Je valide le formulaire." allowDisplay={(d) => { setDoneState(d.done); return true; }} />


                    {submitBlockContent(undefined, "step3")}
                </SubForm>
            </Form>

        </BaseContainer >
    )
}