// src/translatorProvider
import { TranslatorReducerType } from "leeloo-react/model/store/reducer/TranslatorReducerType";
import get from "get-value";
import axios from "axios";
import { LeelooTranslatorConfigType } from "leeloo-react/model/LeelooTranslatorConfigType";
import { DataProviderReturnType } from "leeloo-react/model/dataProvider/DataProviderType";
import { ResourceDataProviderEnum } from "../enum/ResourceDataProviderEnum";

export const TranslatorProvider = (config: LeelooTranslatorConfigType) => {

    return {
        /**
         * Méthode de récupération des données de la langue selectionné
         */
        loadTraduction: async (langSelect: string): Promise<TranslatorReducerType> => {
           const traductionResource = "/resources";
           const langData = (await axios.get(config.apiUrl+traductionResource+"/fr")).data;
           return {
               currentLang: langSelect,
               data: langData
           };
        },

        /**
         * Méthode de récupération d'une clé donnée pour la traduction
         */
        getTrad: (key: string, translatorData: TranslatorReducerType): string => {
            // pris en charge du null
            if (key == null) return '';
            // usage de la lib get-value pour gérer les clés complexe du style "key.subkey"
            const r = get(translatorData.data, key.toString());
            return (r != null) ? r : key;
        }
    };
}
