import React from "react";
import { Alert } from "react-bootstrap";
import { LoginFormType } from "leeloo-react/model/authProvider/LoginFormType";
import { InputField } from "leeloo-react/component/form/field/InputField";
import { SubmitBtn } from "leeloo-react/component/form/action/SubmitBtn";
import logo from "../../../asset/img/logo.png";

export const LoginForm = (props: LoginFormType) => {
    return (
        <section className="vh-100" style={{ backgroundColor: "#ddd" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong">
                            <div className="card-body p-5 text-center">
                                <img className="mb-2" src={logo} width={400} style={{ opacity: 1 }} />

                                <h3 className="mb-4">Connexion</h3>

                                {props.errorMsg && <Alert variant="danger">{props.errorMsg}</Alert>}

                                <InputField className="mb-4" label="Identifiant de l'étude" name="username" />
                                <InputField className="mb-4" label="Code PIN" type="password" name="password" />

                                <SubmitBtn className="btn btn-primary btn-lg btn-block" label="Connexion" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export const LoginFormBlank = () => { return <LoginForm errorMsg="" /> }