import { UniconIconRender } from "leeloo-react/component/display/iconRender/UniconIconRender";
import { LeelooSpa } from "leeloo-react/LeelooSpa";
import React from "react";
import "./bootstrap-litera.min.css";
import "./scss/main.scss"

import { LoginForm } from "./components/page/auth/LoginForm";
import { MenuRouteConfig } from "./config/MenuRouteConfig";

import { RouteConfig } from "./config/RouteConfig";
import { RouteEnum } from "./enum/RouteEnum";
import { NetProvider } from "./dataProvider/NetProvider";
import { TranslatorProvider } from "./dataProvider/TranslatorProvider";
import { NetJwtAuthProvider } from "./dataProvider/NetJwtAuthProvider";

function App() {
  return (
    <LeelooSpa
      apiUrl={process.env.REACT_APP_API_URL!}
      allowDynamicTitleChange
      componentConfig={{
        iconRender: UniconIconRender
      }}
      translator={{
        defaultLang: "fr",
        langAvailable: ["fr"],
        provider: TranslatorProvider
      }}
      dataProvider={NetProvider}
      authProvider={NetJwtAuthProvider}
      defaultPrivateRoute={RouteEnum.SITE}
      loginForm={LoginForm}
      routeConfig={RouteConfig}
      menuConfig={MenuRouteConfig}
      
    />
  );
}

export default App;
