import { AxiosError, AxiosStatic } from "axios";
import qs from "qs";
import { ParametersCrudType } from "leeloo-react/model/dataProvider/ParametersCrudType";
import { ResponseHttpType } from "leeloo-react/model/dataProvider/ResponseHttpType";
import { ParamsDataProviderType } from "leeloo-react/model/dataProvider/DataProviderType";

/**
 * DataProvider simple CRUD générique
 * Usage des valeurs Hydra JSON
 * Compatible avec le framework :
 * - Api-Platform
 * @param apiUrl 
 * @param resource 
 * @returns 
 */
export const NetProvider = ({
    apiUrl,
    axios,
    reportError
}: ParamsDataProviderType) => {

    // permet a axios de lever une exception si le code http de la réponse est différente de 200
    const validateStatus = (status: number) => {
        return (status == 200);
    }

    return {
        getList: async (resource: string, params: ParametersCrudType): Promise<ResponseHttpType> => {

            let query: any = {
                pagination: (params.pagination != null),
            };
            // ajout pagination si voulu
            if (query.pagination) {
                query = {
                    ...query,
                    page: params.pagination!.page,
                    take: params.pagination!.take,
                }
            }
            // ajout tri
            if (params.sort) {
                query = {
                    ...query,
                    order: { [params.sort.field]: params.sort.order },
                }
            }
            if (params.filter) {
                // convertion des valeurs, 
                let filterList = { ...params.filter };
                // for (let key in filterList) {
                //     if (filterList.hasOwnProperty(key)) {
                //         filterList[key] = filterList[key].value;
                //     }
                // }
                query = {
                    ...query,
                    ...filterList
                }
            }


            const url = `${apiUrl}/${resource}?${qs.stringify(query, { skipNulls: true })}`;

            try {
                const r = await axios.get(url, { validateStatus });
                const returnobj = {
                    data: r.data.member,
                    total: r.data.totalItems
                };

                return returnobj;
            } catch (e) {
                //reportError(e as AxiosError);
                throw e;
            }
        },

        checkBedUnicity: async (resource: string, bedNb: string, service: string | undefined): Promise<ResponseHttpType> => {
            const url = `${apiUrl}/${resource}?bedNb=${bedNb}&serviceId=${service}`;

            try {
                const r = await axios.get(url, { validateStatus });
                console.log(r)
                return {
                    data: r.data
                };
            } catch (e) {
                //reportError(e as AxiosError);
                throw e;
            }
        },

        getOne: async (resource: string, params: ParametersCrudType): Promise<ResponseHttpType> => {
            const url = `${apiUrl}/${resource}/${params.id}`;

            try {
                const r = await axios.get(url, { validateStatus });
                console.log(r)
                return {
                    data: r.data
                };
            } catch (e) {
                //reportError(e as AxiosError);
                throw e;
            }
        },

        update: async (resource: string, params: ParametersCrudType): Promise<ResponseHttpType> => {
            const url = `${apiUrl}/${resource}/${params.id}`;

            try {
                const r = await axios.put(url, params.data, { validateStatus });
                return {
                    data: r.data.id,
                    sourceData: r.data?.warning
                }
            } catch (e) {
                //reportError(e as AxiosError);
                throw e;
            }

        },

        create: async (resource: string, params: ParametersCrudType): Promise<ResponseHttpType> => {
            let url = `${apiUrl}/${resource}`;
            if (params.id) {
                url += "/" + params.id;
            }
            try {
                const r = await axios.post(url, params.data, { validateStatus });
                return {
                    data: r.data.id
                }
            } catch (e) {
                // reportError(e as AxiosError);
                throw e;
            }
        },

        delete: async (resource: string, params: ParametersCrudType) => {
            const url = `${apiUrl}/${resource}/${params.id}`;
            try {
                await axios.delete(url, { validateStatus });
            } catch (e) {
                // reportError(e as AxiosError);
                throw e;
            }
        }
    };
}
