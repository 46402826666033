/**
 * Enumeration des routes disponibles ici
 */
export enum RouteEnum {
    HOME = "/",
    SITE = "/site",
    SURVEY_ADD = "/form/:mode",
    SURVEY_EDIT = "/form/:mode/:id",
    QUICK_LOG = "/quicklog/:login/:pin/:siteId/:formId"
}
