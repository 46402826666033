import { SimpleColumn } from "leeloo-react/component/dataTable/column/SimpleColumn";
import { BooleanColumn } from "leeloo-react/component/dataTable/column/BooleanColumn";
import { DataTable } from "leeloo-react/component/dataTable/DataTable";
import React, { useEffect, useState, ChangeEvent } from "react";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../enum/RouteEnum";
import { BaseContainer } from "../../layout/BaseContainer";
import { InputField } from "leeloo-react/component/form/field/InputField";
import { Filter } from "leeloo-react/component/filter/Filter";
import { RouterLink } from "leeloo-react/component/router/RouterLink";
import { useDataProvider } from "leeloo-react/component/hook/useDataProvider";
import { SelectField } from "leeloo-react/component/form/field/SelectField";
import { useDataSource } from "leeloo-react/component/hook/useDataSource";
import { ServiceItemDto } from "../../../model/api-dto";

export const SurveyList = () => {
    const dataProvider = useDataProvider();
    const [etudeInfoState, setEtudeInfoState] = useState({ poleManagement: false, servManagement: false, riskScale: 0 });
    const dsService = useDataSource<ServiceItemDto>({ resource: ResourceDataProviderEnum.SERVICE });

    useEffect(() => {
        if (dataProvider.getOne) {
            // on me l'id "rien" car il faut envoyer un parametre pour respecter le framework
            dataProvider.getOne(ResourceDataProviderEnum.ETUDEINFO, { id: "rien" }).then((d) => {
                setEtudeInfoState({ poleManagement: d.data.poleManagement, servManagement: d.data.serviceManagement, riskScale: d.data.riskScale });
            })
        }
    }, [])

    return (
        <BaseContainer>
            <div className="survey-list col-md-12">
                <DataTable
                    addRoute={RouteEnum.SURVEY_ADD.replace(":mode", "new")}
                    editRoute={RouteEnum.SURVEY_EDIT.replace(":mode", "edit")}
                    filter={
                        <Filter>
                            <div className="col-md-12">
                                <div className="col-md-5 d-inline-block">
                                    <InputField label="Recherche" name="search" />
                                </div>
                                {etudeInfoState.servManagement &&
                                    <>
                                        <div className="col-md-1 d-inline-block">
                                        </div>
                                        <div className="col-md-5 d-inline-block">
                                            <SelectField
                                                className="mb-3 mt-3"
                                                name="serviceName"
                                                label="Filtrer par service"
                                                // clé de la valeur correspondant au DTO inchangé
                                                isSearchable={false}
                                                // la resource externe sous forme de dataSource pour charger les données
                                                externalResource={{ dataSource: dsService, labelKey: "label", valueKey: "label" }}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </Filter>
                    }
                    allowDelete={() => true}
                    resource={ResourceDataProviderEnum.FORM}
                    disabledPagination={true}
                    disabledTotalFound={true}
                >
                    <SimpleColumn title="bedNb" field="bedNb" />
                    {etudeInfoState.servManagement &&
                        <SimpleColumn title="service" field="service" />
                    }
                    <BooleanColumn iconFalse="uil uil-square uil-xl" iconTrue="uil uil-check-square uil-xl" title="done" field="done" />
                </DataTable>
            </div>
        </BaseContainer >
    );
};
