import React from "react";
import { useRouter } from "leeloo-react/component/hook/useRouter";
import { RouterMenuLink } from "leeloo-react/component/router/RouterMenuLink";
import logo from "../../asset/img/logo-simple.png";
import { LogoutLink } from "leeloo-react/component/auth/logout/LogoutLink";
import { Icon } from "leeloo-react/component/display/Icon";
import { useAuthProvider } from "leeloo-react/component/hook/useAuthProvider";

type Props = {
    children?: JSX.Element | JSX.Element[]
}

/**
 * Containeur de base de toute les pages
 */
export const BaseContainer = (props: Props) => {
    const { currentLabelRoute } = useRouter();
    const authProvider = useAuthProvider();

    return (<>
        <div className="navbar navbar-expand-lg fixed-top navbar-light bg-light mb-2">
            <div className="container">
                <img src={logo} className="navbar-brand" width={100} style={{ opacity: 1 }} />

                <div>
                    {authProvider && (authProvider.authData.username + " - " + authProvider.authData.firstname)}
                </div>
                <ul className="navbar-nav ms-md-auto">
                    <li className="nav-item">
                        <LogoutLink className="nav-link"><div style={{ cursor: "pointer" }}><Icon name="uil uil-signout" /> Se déconnecter</div></LogoutLink>
                    </li>
                </ul>
            </div>
        </div>
        <div className="container" style={{ marginTop: "100px" }}>
            <div className="page-header" id="banner">
                <div className="row">
                    {props.children}
                </div>
            </div>
        </div>
    </>);
};
