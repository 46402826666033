
export enum ResourceDataProviderEnum {
    FORM = "form",
    FORM_STATUS = "form/status",
    FORM_EXIST = "form/exist",
    ESCARRE = "escarre",

    /* URL API */
    /* Session */
    URL_LOGIN = "login",

    /* Referentiel */
    RESSOURCES = "resources",
    EQUIPEMENTS = "equipements",
    POLES = "poles",
    ETUDEINFO = "form/info",
    SITE = "site",
    SERVICE = "service",

}