import { AxiosError, AxiosStatic } from "axios";
import { LoginType } from "leeloo-react/model/authProvider/LoginType";
import { AuthReducerType } from "leeloo-react/model/store/reducer/AuthReducerType";
import { ResetPasswordType } from "leeloo-react/model/authProvider/ResetPasswordType";
import { ResourceDataProviderEnum } from "../enum/ResourceDataProviderEnum";
import { Destination } from "@rpldy/uploady";

export const NetJwtAuthProvider = (apiUrl: string) => {
    return {
        /** 
         * Gère l'authentification a l'API + gestion du token ou autre
         * @return Une donnée pour indiquer si la connection a réussie, renvoi null si échoue
         * La donnée sera stocké dans le reducer "auth" de Leeloo pour un usage futur
         */
        login: async (axios: AxiosStatic, loginData: LoginType): Promise<AuthReducerType | null> => {
            const url = `${apiUrl}/${ResourceDataProviderEnum.URL_LOGIN}`;
            let params = {};

            // cas du QuickLog
            if (loginData.username && loginData.password && loginData.identifier) {
                params = { email: loginData.username, password: loginData.password.toString(), siteId: loginData.identifier };
            }
            else if (loginData.username && loginData.password) {
                // mode auth form
                params = { email: loginData.username, password: loginData.password };
            } else if (loginData.identifier) {
                // selection du site a connecter (siteId via identifier)
                params = { email: "", password: "", siteId: loginData.identifier };
            } else {
                // aucun des deux detecté, le login a probablement échoué
                throw "message.loginFail";
            }

            // tentative de connexion
            try {
                const r = await axios.post(url, params);
                if (r.status == 200) {
                    if (r.data.specificError) {
                        throw r.data.specificError;
                    }
                    else {
                        var ret = {
                            token: r.data.token,
                            username: r.data.userName,
                            firstname: r.data.firstName,
                            lastname: r.data.lastName,
                            roles: r.data.roles
                        };
                        return ret;
                    }

                }
                else {
                    throw "message.loginFail";
                }
            } catch (e) {
                if (typeof e === 'string') {
                    throw e;
                } else {
                    throw "message.loginFail";
                }
            }
        },

        /**
         * Gère la configuration d'axios pour l'accès sécurisé au API
         * dataAuth contiens les valeurs précédemment renvoyé par le dernier login effectué
         * Exemple : Injection du token bearer
         */
        makeCredential: (axios: AxiosStatic, dataAuth: AuthReducerType) => {
            // assignation du token client
            if (dataAuth.token) {
                axios.defaults.headers.common['Authorization'] = "Bearer " + dataAuth.token;
            }
        },

        makeUploadCredential: (paramsUpload: Destination, dataAuth: AuthReducerType) => {
            
        },

        /**
         * Gère l'autorisation d'accès selon la façon dont le connecteur gère les "roles"
         * @param dataAuth : Donnée récupéré depuis le login
         * @param requestRole : Role a vérifier les droits
         * @return Si false, le client renverra la page 403 forbidden
         */
        checkAccess: (dataAuth: AuthReducerType, requestRole: string[]) => {
            let access = false;
            requestRole.forEach((r) => {
                if (!access && dataAuth.roles != null && dataAuth.roles.indexOf(r) > -1) {
                    access = true;
                }
            });
            return access;
        },

        /**
         * Gère les erreurs API pour détecter qu'on a plus accès a celle ci
         * @return Un boolean pour indiquer si il faut se réauthentifier ou pas
         */
        checkError: async (error: AxiosError<any>) => {
            const status = error.response?.status;
            return (status === 401 || status === 403);
        }

    }
}
